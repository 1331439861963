@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@100;200;300;400;500;700;800;900');
@import url('https://fonts.googleapis.com/css2?family=Cookie:wght@100;200;300;400;500;700;800;900');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;700;800;900');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;700;800;900');

@layer components {
  .whiteHalo {
    box-shadow: 0 0 4px 2px white;
  }

  @media screen and (min-width: 768px) {
    .whiteHalo {
      box-shadow: 0 0 16px 8px white;
    }
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
